import {
  Button,
  Icon,
  Logo,
  NavbarDropdown,
  NavbarGrid,
  Typography,
} from "@sphtech/dmg-design-system";
import cx from "classnames";

import customStyles from "./custom.module.css";
import styles from "./navbaricon.module.css";

const { NavLink, Heading } = Typography;

const NavbarICON = ({
  navObject,
  sticky,
  currentLocation,
}: React.ComponentProps<typeof NavbarGrid>) => {
  const {
    LogoSource,
    routes,
    SearchIcon,
    MenuIcon,
    toggleSidebar,
    ArticleTitle,
    dropdownTeasers,
  } = navObject;
  const { pathname } = currentLocation;

  return (
    <div className={styles.persistent}>
      <div
        className={cx(
          styles.header,
          sticky && styles.sticky,
          ArticleTitle && styles.title,
        )}
      >
        {/* iconTopSection for animation */}
        <section
          className={cx(
            styles.displayGrid,
            styles.headerTemplate,
            styles.iconTopSection,
          )}
        >
          {/* grid-area: menu; */}
          <button
            onClick={toggleSidebar}
            className={styles.hamburgerMenuBtnIcon}
            title="Menu"
            data-layout-position="menubutton"
          >
            <Icon>{MenuIcon}</Icon>
          </button>

          {/* grid-area: NONE; */}
          <div></div>

          {/* grid-area: pri; */}
          {(!sticky || !ArticleTitle) && (
            <div
              className={cx(styles.primary_items)}
              data-layout-position="navbar"
            >
              {routes.primaryLinks.map((item, index) => {
                if (item.as === "button") {
                  return (
                    <Button
                      key={index}
                      variant="primary"
                      href={item.url}
                      target={item.target}
                    >
                      {item.name}
                    </Button>
                  );
                }

                if (item.as === "dropdown") {
                  return (
                    <NavbarDropdown key={index} dropdownList={dropdownTeasers}>
                      <NavLink
                        href={item.url}
                        customClassName={customStyles["icon-nav-links-header"]}
                      >
                        {item.name}
                      </NavLink>
                    </NavbarDropdown>
                  );
                }

                return (
                  <NavLink
                    key={index}
                    href={item.url}
                    customClassName={customStyles["icon-nav-links-header"]}
                    isActive={pathname === item.url}
                  >
                    {item.name}
                  </NavLink>
                );
              })}
            </div>
          )}

          {/* grid-area: pri; */}
          {sticky && ArticleTitle && (
            <div className={cx(styles.article_item)}>
              <Heading.SubHeading4>{ArticleTitle}</Heading.SubHeading4>
            </div>
          )}

          {/* grid-area: search; */}
          <a
            href={routes.searchBarLink.url}
            title={routes.searchBarLink.name}
            className={styles.searchIcon}
          >
            <Icon>{SearchIcon}</Icon>
          </a>

          {/* grid-area: util; */}
          {!!routes.utilityLinks.length && (
            <div
              className={cx(styles.utility_items)}
              data-layout-position="navbar"
            >
              {routes.utilityLinks.map((item, index) => {
                if (item.as === "text") {
                  return (
                    <NavLink key={index} href={item.url}>
                      {item.name}
                    </NavLink>
                  );
                }
                return (
                  <Button
                    key={index}
                    variant="primary"
                    href={item.url}
                    target={item.target}
                  >
                    {item.name}
                  </Button>
                );
              })}
            </div>
          )}
        </section>

        {/* iconBottomSection for animation */}
        <section className={cx(styles.logo, styles.iconBottomSection)}>
          <Logo
            href="/"
            src={LogoSource}
            width="100%"
            height="100%"
            alt="logo"
          />
        </section>
      </div>
    </div>
  );
};

export default NavbarICON;
