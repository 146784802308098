import { ConfigHeader } from "../../types/config";
import * as Icons from "./socialIcons";

export const configHeader: ConfigHeader = {
  megaMenu: {
    newsletter: {
      text: "订阅《风华》电子报，掌握最新人物专访、艺术与文化新趋势、时尚动态、珠宝与腕表、美容及生活资讯。",
      buttonText: "SIGN UP",
      url: "/newsletter-signup",
    },
    closeButton: {
      type: "icon",
      placement: "right",
    },
  },
  socialMedia: {
    target: "_blank",
    icons: [
      {
        icon: Icons.InstagramIcon,
        name: "Instagram",
        href: "https://www.instagram.com/iconsingapore/",
      },
      {
        icon: Icons.FacebookIcon,
        name: "Facebook",
        href: "https://www.facebook.com/SingaporeICON/",
      },
      {
        icon: Icons.YoutubeIcon,
        name: "Youtube",
        href: "https://www.youtube.com/channel/UCpIfuwBnqxQb267s_LGKcUQ",
      },
    ],
  },
};

export const hamburgermenuSvgSource = (
  <svg
    width="36"
    height="17"
    viewBox="0 0 36 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.407715" width="35" height="2" fill="#4D4D4D" />
    <rect x="0.5" y="7.40771" width="35" height="2" fill="#4D4D4D" />
    <rect x="0.5" y="14.4077" width="35" height="2" fill="#4D4D4D" />
  </svg>
);
