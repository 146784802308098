import "@sphtech/dmg-design-system/dist/theme-icon.css";
import "@sphtech/dmg-design-system/assets/icon/fonts.css";

import { configSite } from "@pub/config";
import { BaseLayout, CustomClassContext } from "@sphtech/dmg-design-system";
import Newsticker from "@src/app/components/Modules/Newsticker/Newsticker";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { configHeader, hamburgermenuSvgSource } from "./config/configHeader";
import customStyle from "./custom.module.css";
import logoSvgSource from "./logo.svg?raw";
import NavbarICON from "./NavbarICON";
import { RoutePaths } from "./routePaths";

type BaseLayoutProps = {
  ArticleTitle?: string;
  children: React.ReactNode;
  ads?: boolean;
  leaderboard?: boolean;
  skipMobileBanner?: boolean;
};

/**
 * Reusable Base layout specific to ICON.
 */
const IconBaseLayout = ({
  ArticleTitle,
  children,
  ads = true,
  leaderboard = true,
  skipMobileBanner,
}: BaseLayoutProps) => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();

  return (
    <CustomClassContext.Provider
      value={{
        button: {
          primary: customStyle["icon-button-primary"],
          secondary: customStyle["icon-button-secondary"],
        },
        figureCaptions: {
          imageCaption: customStyle["icon-image-caption"],
        },
        heading: {
          headingBase: customStyle["icon-heading-base"],
          headingSplit: customStyle["icon-heading-split"],
        },
        navLinks: {
          verticalLineFooter: customStyle["icon-nav-links-footer"],
        },
        textLink: customStyle["icon-text-link"],
        navbar: {
          dropDown: customStyle["navbarDropdown"],
        },
      }}
    >
      <BaseLayout
        siteName={configSite.name}
        ArticleTitle={ArticleTitle}
        ads={ads}
        leaderboard={leaderboard}
        NewsTicker={<Newsticker />}
        RoutePaths={RoutePaths}
        svgSource={logoSvgSource}
        svgHamburgerMenu={hamburgermenuSvgSource}
        mciText={
          <span suppressHydrationWarning>
            MDDI (P) 030/11/2024. Published by SPH Media Limited, Co. Regn. No.
            202120748H. Copyright © {currentYear} SPH Media Limited. All rights
            reserved.
          </span>
        }
        socialIconProps={configHeader.socialMedia}
        megaMenuProps={{
          NewsLetterProps: configHeader.megaMenu.newsletter,
          CloseButtonProps: configHeader.megaMenu.closeButton,
          divider: configHeader.megaMenu.divider?.footer,
        }}
        skipMobileBanner={skipMobileBanner}
        currentLocation={location}
        NavbarComponent={NavbarICON}
      >
        <Helmet
          htmlAttributes={{
            lang: "zh-SG",
          }}
        >
          <link
            rel="icon"
            type="image/png"
            sizes="48x48"
            href="/favicon-48x48.png"
          />
          <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
          <link rel="shortcut icon" href="/favicon.ico" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#xxx" />
          <meta name="msapplication-TileColor" content="#xxx" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        {children}
      </BaseLayout>
    </CustomClassContext.Provider>
  );
};

export default IconBaseLayout;
