import { CAAS_INDEX_SUFFIX, DISABLE_ADS } from "@app/constants";
import {
  GA4_REF_CODE_CONTENTBLOCK_B,
  GA4_REF_CODE_CONTENTBLOCK_F,
  GA4_REF_CODE_CONTENTBLOCK_H,
  GA4_REF_CODE_CONTENTBLOCK_L,
} from "@app/utils/ga/ref-code";
import {
  BRIGHTCOVE_CONFIG,
  DEFAULT_CONFIG_GAM,
  LOTAME_CONFIG,
  PREMUTIVE_CONFIG,
} from "@src/pubs/common/config";

import {
  ConfigAboutUs,
  ConfigAffiliatesDeclaration,
  ConfigAuthorProfile,
  ConfigBrightcove,
  ConfigCaas,
  ConfigCategory,
  ConfigContentHub,
  ConfigError,
  ConfigExploreMore,
  ConfigGAM,
  ConfigGTM,
  ConfigHome,
  ConfigLatestStory,
  ConfigLotame,
  ConfigNewsletter,
  ConfigPermutive,
  ConfigSearch,
  ConfigSingleStory,
  ConfigSite,
  ConfigSkimlinks,
  ConfigTag,
  ConfigTeaser,
  ConfigTrackingCode,
  ConfigVideoLanding,
} from "../types/config";
import { CHAR_WRAPPERS } from "./config/charWrappers";
import {
  WhiteArrowRoundLeft,
  WhiteArrowRoundRight,
} from "./config/commonIcons";
import newsletterStandaloneBg from "./newslettersignupbg.jpg";
import newsletterHomeBg from "./newslettersignuphomebg.jpg";

// ToDo:
// import logoPath from "./logo.svg?url";
// import newsletterBg from "./newsletterbg.png";
const logoPath = "";

export const configSite: ConfigSite = {
  name: "ICON Singapore",
  address:
    import.meta.env.VITE_PUBLIC_BASE_URL || "https://www.iconsingapore.com", // without trailing slash
  tagline: "Where ICONS Inspire 品味精致生活",
  description:
    "ICON is a luxury guide for today’s affluent and bilingual women, featuring an eclectic mix of high fashion, beauty trends, essential readers and profiles of the rich and famous.",
  titleSeparator: "—",
  logoPath,
  pageShare: {
    fill: "#4D4D4D",
    media: [
      {
        name: "Facebook",
      },
      {
        name: "Whatsapp",
      },
      {
        name: "X",
      },
      {
        name: "Email",
      },
    ],
  },
  splitZHSectionName: true,
  aboutUs: {
    headlineBelow: true,
  },
  newsticker: {
    label: "联合早报精选:",
    rssUrl: "https://www.zaobao.com.sg/RSS/Realtime.xml",
    duration: 300,
  },
  charWrappers: CHAR_WRAPPERS,
};

export { configHeader } from "./config/configHeader";

export const configCaas: ConfigCaas = {
  index: `icon${CAAS_INDEX_SUFFIX}`,
  tagIdPrefix: "tag:icon-keywords@sph.com.sg,2023:",
  cuePublicationName: "icon",
};

export const configGTM: ConfigGTM = {
  container: "GTM-N6M9VWL",
  siteVerification: "_cGEdj2ZMeQhb_OTIAKvlCGbSxaBwct6M6XrDxywcXM",
};

export const configGAM: ConfigGAM = {
  ...DEFAULT_CONFIG_GAM,
  account: "/5908/icon-sg", // ToDo
};

export const configLotame: ConfigLotame = {
  ...LOTAME_CONFIG,
  active: !DISABLE_ADS,
};

export const configPermutive: ConfigPermutive = {
  ...PREMUTIVE_CONFIG,
  active: !DISABLE_ADS,
};

export const configSkimlinks: ConfigSkimlinks = {
  active: !DISABLE_ADS,
  skimlinkId: "", // ToDo
  geniuslinkId: "", // ToDo
};

export const configBrightcove: ConfigBrightcove = {
  ...BRIGHTCOVE_CONFIG,
};

export const configSingleStory: ConfigSingleStory = {
  authorProfile: true,
  byline: {
    separator: "\u00a0/\u00a0",
    authors: {
      byWord: "文\u00a0/\u00a0",
      separator: "\u00a0/\u00a0",
    },
  },
  configShareArticle: {
    label: "Share this article",
    divider: {
      top: false,
    },
  },
  topDivider: false,
  embeddedGallery: {
    leftIcon: WhiteArrowRoundLeft,
    rightIcon: WhiteArrowRoundRight,
  },
  gallery: {
    paginationSeparator: "/",
  },
  featureStory: {
    bodyMaxWidth: 890,
  },
  displayTags: {
    displayTopTags: true,
    displayTagHeading: false,
  },
  relatedArticle: {
    headerText: "继续阅读",
    hasRelatedPrefix: false,
  },
  sponsorshipBox: {
    showCtaIcon: false,
  },
};

export const configLatestStory: ConfigLatestStory = {
  latestStoryLimit: 21,
  subAnchorLimit: 3,
  landscapeIndexes: [0, 1, 2],
  mainContentBlockDisplay: "grid",
  mainContentBlockMode: "landscape",
  errorLayoutMode: "landscape",
  portraitImageWidth: 285,
  landscapeImageWidth: 310,
};

export const configCategory: ConfigCategory = {
  pinnedTeasersLimit: 3,
  latestStoriesLimit: 8,
  videoGalleryLandscape: {
    display: true,
    hasLabelContainer: false,
    spotlight: {
      arrowSize: 14,
      arrowVariant: "circle",
    },
    mobileNavigation: true,
    mobileNavLooping: {
      arrowSize: 14,
      arrowVariant: "circle",
    },
  },
  dividerVariant: "secondary",
};

export const configContentHub: ConfigContentHub = {
  configMasthead: {
    heading: {
      display: false,
      displayBelowFeaturedImage: true,
    },
    gradientOverlay: {
      display: true,
    },
  },
  configBodyContent: {
    display: true,
  },
  configShareButton: {
    display: false,
  },
  configEditorsNote: {
    display: false,
  },
  configCallQoute: {
    display: true,
  },
  configFeaturedSection: {
    display: true,
  },
  configDeals: {
    display: true,
    props: {
      heading: "editor's picks",
      noOfItemsInFrame: 4,
    },
  },
  configTopStories: {
    display: true,
  },
  storiesPerPage: {
    topStories: 8,
    numberOfStories: 14,
  },
  configListingStories: {
    mode: "landscape",
  },
  contenthubBlockF: {
    mode: "landscape",
  },
  layout: {
    stories: {
      elements: [
        { name: "contentBlockI", limit: 7 },
        { name: "adsMidContent" },
      ],
    },
  },
};

export const configTag: ConfigTag = {
  topStoriesLimit: 4,
  listedStoriesLimit: 12,
  itemSeparator: ", ",
  subHeading: "Tag",
};

export const configAmazonAffiliates = {};

export const configSearch: ConfigSearch = {
  loadMoreText: "Loading more results",
};

export const configExploreMore: ConfigExploreMore = {
  heading: "精华",
};

export const configVideoLanding: ConfigVideoLanding = {};

export const configHome: ConfigHome = {
  isHeadingClickable: true,
  headingDivider: false,
  viewMore: { icon: false },
  latestArticle: {
    landscapeIndexes: [0, 3],
    mobileMode: "landscape",
  },
  layoutGrid: {
    elements: [
      {
        name: "anchorBlock",
        dataSource: {
          type: "list",
          key: "anchorblock",
          fallback: "latest",
          limit: 1,
        },
      },
      {
        name: "latestArticle",
        dataSource: {
          type: "list",
          key: "pinned",
          fallback: "latest",
          limit: 4,
        },
      },
      {
        name: "contentBlockI",
        dataSource: {
          type: "tag",
          key: "topic-0",
          refCode: "contenthub-a",
          limit: 7,
          min: 1,
        },
      },
      {
        name: "contentBlockG",
        dataSource: {
          type: "section",
          key: "people",
          refCode: "block-e",
          limit: 4,
        },
      },
      { name: "adUnit" },
      {
        name: "contentBlockF",
        dataSource: {
          type: "section",
          key: "fashion",
          refCode: "block-f",
          limit: 3,
        },
      },
      {
        name: "contentBlockL",
        dataSource: {
          type: "section",
          key: "watches-and-jewellery",
          refCode: "contentblock",
          limit: 5,
        },
      },
      {
        name: "contentBlockI",
        dataSource: {
          type: "tag",
          key: "topic-1",
          refCode: "contenthub-b",
          limit: 7,
          min: 1,
        },
      },
      {
        name: "contentBlockF",
        dataSource: {
          type: "section",
          key: "beauty",
          refCode: "contentblock",
          limit: 3,
        },
      },
      {
        name: "contentBlockD",
        dataSource: {
          type: "list",
          key: "trending",
          fallback: "trending",
          refCode: "trending",
          limit: 3,
        },
      },
      {
        name: "contentBlockGridAreas",
        dataSource: {
          type: "section",
          key: "lifestyle",
          refCode: "block-a",
          limit: 5,
        },
      },
      { name: "magazineListing" },
      { name: "newsletter" },
    ],
  },
  magazineListing: {
    buttonVariant: "primary",
    midDivider: false,
    arrowSize: 14,
    arrowVariant: "circle",
  },
  newsletter: {
    containerImageBg: newsletterHomeBg,
    newsletterBody:
      "订阅《风华》电子报，掌握最新人物专访、艺术与文化新趋势、时尚动态、珠宝与腕表、美容及生活资讯。",
    newsletterLang: "zh",
  },
  contentBlockD: {
    mobileMode: "landscape",
  },
  contentBlockF: {
    landscapeIndexes: [0],
    mobileMode: "landscape",
    anchorBlock: {
      index: 0,
      mediaScreen: "all-screen",
      hideVerticalLine: false,
    },
  },
  contentBlockG: {
    hideIMU: true,
    mobileMode: "landscape",
  },
  contentBlockGridAreas: {
    mobileMode: "landscape",
  },
  contentBlockL: {
    landscapeIndexes: [0, 1, 2, 3, 4],
    anchorBlock: {
      index: 0,
      mediaScreen: "all-screen",
      hideVerticalLine: false,
    },
  },
  trendingStories: { publisher: "iconsingapore" },
};

export const configNewsletter: ConfigNewsletter = {
  image: newsletterStandaloneBg,
  title:
    "订阅《风华》电子报，掌握最新人物专访、艺术与文化新趋势、时尚动态、珠宝与腕表、美容及生活资讯。",
  lang: "zh",
};

export const configTeaser: ConfigTeaser = {
  publishDate: false,
  anchorBlock: {
    mobileCrop: "landscape",
    publishDate: false,
  },
  mobileMode: "landscape",
};

export const configTrackingCode: ConfigTrackingCode = {
  home: {
    topic: ["contenthub-a", "contenthub-b"],
    trending: "trending",
    blockg: "block-d",
    blockf: ["hometours", "block-c"],
    blockd: "howto",
  },
  category: {
    mainContentBlock: GA4_REF_CODE_CONTENTBLOCK_L,
  },
  exploremore: "exploremore",
  contenthub: {
    topstories: {
      contentBlockB: GA4_REF_CODE_CONTENTBLOCK_B,
      contentBlockF: GA4_REF_CODE_CONTENTBLOCK_F,
      contentBlockI: GA4_REF_CODE_CONTENTBLOCK_H,
    },
    listing: GA4_REF_CODE_CONTENTBLOCK_H,
  },
  customSection: ["hometours", "howto"],
};

export const configAffiliatesDeclaration: ConfigAffiliatesDeclaration = {
  text: "This article contains affiliate links. If you buy through these links, we may earn a small commission.",
};

export const configAboutUs: ConfigAboutUs = {
  profileBlock: {
    heading: "我们的团队",
  },
};

export const configError: ConfigError = {
  relatedTitle: "或许您会对以下为文章感兴趣",
  relatedAltTitle: "Perhaps what you are looking for is here.",
  messages: {
    status404: {
      title: "抱歉！您所查询的页面不存在",
      altTitle: "We are sorry, the page you are looking for cannot be found.",
    },
  },
};

export const configAuthorProfile: ConfigAuthorProfile = {
  hideDivider: true,
  hideAds: true,
};
