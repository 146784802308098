const InstagramIcon = (
  <svg
    width="44"
    height="45"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22.1357" r="22" fill="#4D4D4D" />
    <g clipPath="url(#clip0_4539_6194)">
      <path
        d="M26.4162 30.1357H17.5836C15.3318 30.1357 13.5 28.212 13.5 25.8474V17.4243C13.5 15.0595 15.3318 13.1357 17.5836 13.1357H26.4162C28.6682 13.1357 30.5 15.0595 30.5 17.4243V25.8474C30.5 28.212 28.6682 30.1357 26.4162 30.1357ZM17.5836 14.4261C16.0095 14.4261 14.7287 15.7712 14.7287 17.4243V25.8474C14.7287 27.5004 16.0095 28.8454 17.5836 28.8454H26.4162C27.9903 28.8454 29.2713 27.5004 29.2713 25.8474V17.4243C29.2713 15.7712 27.9905 14.4261 26.4162 14.4261H17.5836Z"
        fill="white"
      />
      <path
        d="M21.9998 26.3106C19.5344 26.3106 17.5288 24.2044 17.5288 21.6152C17.5288 19.026 19.5344 16.9199 21.9998 16.9199C24.4651 16.9199 26.4707 19.0262 26.4707 21.6152C26.4707 24.2042 24.4651 26.3106 21.9998 26.3106ZM21.9998 18.2103C20.212 18.2103 18.7575 19.7377 18.7575 21.6151C18.7575 23.4924 20.212 25.0201 21.9998 25.0201C23.7875 25.0201 25.242 23.4925 25.242 21.6151C25.242 19.7376 23.7875 18.2103 21.9998 18.2103Z"
        fill="white"
      />
      <path
        d="M26.9671 17.3724C27.3686 17.3724 27.694 17.0306 27.694 16.6091C27.694 16.1875 27.3686 15.8457 26.9671 15.8457C26.5657 15.8457 26.2402 16.1875 26.2402 16.6091C26.2402 17.0306 26.5657 17.3724 26.9671 17.3724Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4539_6194">
        <rect
          width="17"
          height="17"
          fill="white"
          transform="translate(13.5 13.1357)"
        />
      </clipPath>
    </defs>
  </svg>
);

const FacebookIcon = (
  <svg
    width="44"
    height="45"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22.1357" r="22" fill="#4D4D4D" />
    <path
      d="M25.8465 23.8203L26.3651 20.3854H23.1234V18.1535C23.1234 17.2143 23.5747 16.2963 25.027 16.2963H26.5V13.3679C25.6338 13.2254 24.7624 13.1463 23.8859 13.1357C21.2147 13.1357 19.4668 14.782 19.4668 17.7631V20.3854H16.5V23.8256H19.4668V32.1357H23.1183V23.8256H25.8413L25.8465 23.8203Z"
      fill="white"
    />
  </svg>
);

const YoutubeIcon = (
  <svg
    width="44"
    height="45"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22.1357" r="22" fill="#4D4D4D" />
    <path
      d="M32.0609 26.9499C31.8199 27.8103 31.1076 28.4877 30.2043 28.718C28.5673 29.1357 22 29.1357 22 29.1357C22 29.1357 15.4327 29.1357 13.7957 28.718C12.8924 28.4877 12.1801 27.8103 11.9391 26.9499C11.5 25.3896 11.5 22.1357 11.5 22.1357C11.5 22.1357 11.5 18.8819 11.9391 17.3215C12.1801 16.4612 12.8924 15.7838 13.7957 15.5535C15.4339 15.1357 22 15.1357 22 15.1357C22 15.1357 28.5673 15.1357 30.2043 15.5535C31.1076 15.7838 31.8199 16.4612 32.0609 17.3215C32.5 18.8807 32.5 22.1357 32.5 22.1357C32.5 22.1357 32.5 25.3896 32.0609 26.9499ZM19.8523 19.1811V25.0904L25.3409 22.1357L19.8523 19.1811Z"
      fill="white"
    />
  </svg>
);

export { FacebookIcon, InstagramIcon, YoutubeIcon };
