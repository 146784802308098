import { RoutePathsType } from "../types/routePaths";

export const RoutePaths: RoutePathsType = {
  NavBar: {
    primaryLinks: [
      {
        name: "人物",
        url: "/people",
      },
      {
        name: "时尚",
        url: "/fashion",
      },
      {
        name: "精表珠宝",
        url: "/watches-and-jewellery",
      },
      {
        name: "美容",
        url: "/beauty",
      },
      {
        name: "生活",
        url: "/lifestyle",
      },
      // SPECIALS replaced by specific navlinks to ICON Ball and ICON Spa Awards
      // {
      //   name: "特辑",
      //   url: "/topics/icon-ball-2024",
      // },
      {
        name: "特辑",
        as: "dropdown",
      },
    ],
    utilityLinks: [
      {
        id: "magazineIssues", // allow overwrite of name and url from data
        name: "Subscribe",
        url: "https://subscribe.sph.com.sg/package/subscribe-icon-singapore/",
        as: "button",
        variant: "primary",
        target: "_blank",
      },
    ],
    searchBarLink: {
      name: "Search",
      url: "/search",
    },
    mobileUtilityLinks: {
      // login: {
      //   name: "Login",
      //   url: "/",
      // },
    },
  },
  Footer: [
    // make sure to update following css grid token if changing the number of items
    // --header-mega-menu-footer-items-grid-template
    {
      text: "ABOUT US",
      url: "/about-us",
    },
    {
      text: "ADVERTISE WITH US",
      url: "https://www.sph.com.sg/advertising-solutions/",
      target: "_blank",
    },
    {
      text: "CONTACT",
      url: "https://www.sph.com.sg/contact-us/",
      target: "_blank",
    },
    {
      text: "TERMS AND CONDITIONS",
      url: "https://www.sph.com.sg/legal/website_tnc/",
      target: "_blank",
    },
    {
      text: "PRIVACY POLICY",
      url: "https://www.sph.com.sg/legal/sph_privacy/",
      target: "_blank",
    },
    {
      text: "PDPA",
      url: "https://www.sph.com.sg/legal/pdpa/",
      target: "_blank",
    },
  ],
  MegaMenu: [
    {
      text: "人物",
      url: "/people",
      placement: "left",
      subMenu: [],
    },
    {
      text: "时尚",
      url: "/fashion",
      placement: "right",
      subMenu: [],
    },
    {
      text: "精表珠宝",
      url: "/watches-and-jewellery",
      placement: "left",
      subMenu: [],
    },
    {
      text: "美容",
      url: "/beauty",
      placement: "right",
      subMenu: [],
    },
    {
      text: "生活",
      url: "/lifestyle",
      placement: "left",
      subMenu: [],
    },
    // Topic story entry point replaced by self-service from Mag Issues in Cue
    // {
    //   text: "农历新年",
    //   url: "/topics/chinese-new-year",
    //   placement: "left",
    //   subMenu: [],
    // },
  ],
};
