export const WhiteArrowRoundLeft = (
  <span style={{ marginLeft: "30px" }}>
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="18.5508" r="18" stroke="white" />
      <path
        d="M22 11.5508L16 18.0508L22 24.5508"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  </span>
);

export const WhiteArrowRoundRight = (
  <span style={{ marginRight: "30px" }}>
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="19"
        cy="18.5508"
        r="18"
        transform="rotate(-180 19 18.5508)"
        stroke="white"
      />
      <path
        d="M16 25.5508L22 19.0508L16 12.5508"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  </span>
);
